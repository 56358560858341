<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    modelValue: {
        type: String,
        required: true,
    },
});

defineEmits(['update:modelValue']);

const input = ref(null); 

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
}); 

defineExpose({ focus: () => input.value.focus() }); 
</script>

<template>
    <input
        class="border-gray-400 dark:border-gray-700 dark:bg-gray-200 text-gray-800 dark:text-gray-900 focus:border-gray-500 dark:focus:border-gray-800 focus:ring-gray-600 dark:focus:ring-gray-700 rounded-md shadow-sm placeholder:text-gray-500"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)" 
        ref="input"
    />
</template>
